@import "../../assets/theme/vars";

.wrapper_surveys {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .content_surveys {
    width: calc(100% - 250px);
    height: 100%;
    .box_more_survey {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: "is_medium";
      .btn_ellipsis {
        background: #dddddd !important;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
        border-radius: 50%;
        background: $bg_grey;
        cursor: pointer;
        color: #000000;
      }
    }
    .content_ttls {
      margin-bottom: 2rem;
      .ttl {
        color: #e69eac;
      }
    }
    .boxes_surveys {
      margin-top: 0.5rem;
      .card {
        min-height: 275px;
        height: 100%;
      }
      .box_list_surveys {
        .list-group-item {
          border: 0;
          border-left: 3px solid #dddddd;
          padding: 0.25rem 0.75rem;
          border-radius: 0;
          margin-bottom: 0.75rem;
          opacity: 0.5;
          cursor: pointer;
          &.active {
            background: #f6f6f6 !important;
            border-left: 3px solid var(--primary_color);
            opacity: 1;
            color: var(--primary_color) !important;
          }
          &:hover {
            background: #f6f6f6 !important;
            opacity: 1;
            color: $black;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .aside_lateral {
    position: fixed;
    top: 65px;
    right: 0;
    background: #ffffff;
    width: 100%;
    max-width: 250px;
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    z-index: 9;
    &.is-show {
      right: 0;
    }
    .btn_ellipsis_close {
      display: none;
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      font-size: 1.25rem;
    }
    .box_avatar_user {
      margin: 2rem auto 1.5rem;
      width: 135px;
      height: 135px;
      .avatar{
        width: 135px;
      height: 135px;
      }
    }
    .prev_surveys {
      margin-bottom: 4rem;
      width: 100%;
      .box_item_surv{
        padding-top: 1rem;
        overflow: auto;
        max-height: 170px;
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #eeeeee;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aaaaaa;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #aaaaaa;
        }
      }
      .item_prev_surveys {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        span {
          font-size: 1.5rem;
          width: 30px;
          color: #4f38a3;
        }
      }
    }
  }
}

.section_surveys {
  text-align: center;
  padding: 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 100%;
  flex-direction: column;
  .slick-slider {
    width: 100%;
  }
  .slick-list {
    height: 100% !important;
  }
  .question {
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #444444;
  }
  .answers {
    margin: 1rem 0;
  }
  .box_arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      font-family: "is_medium";
      font-size: 0.9rem;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      .fas {
        font-size: 1.5rem;
      }
    }
  }
}

.content_ttls {
  background: #f6e7ec;
  border-radius: $border_radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 160px;
  .box_img_surveys {
    text-align: center;
    img {
      width: 65%;
    }
  }
}

.ico_round {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #999999;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  font-size: 2.25rem;
  color: #999999;
  border-radius: 50%;
}

.surveys {
  &:hover {
    .ico_left {
      margin-right: 0.75rem !important;
    }
  }
  .ico_up {
    display: none;
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: var(--primary_color) !important;
      background-color: var(--primary_color) !important;
      width: 20px;
      height: 20px;
      top: 0px;
      left: -30px;
    }
    &::after {
      display: none !important;
    }
  }

  .custom-control-label {
    cursor: pointer;
    &::before {
      width: 20px !important;
      height: 20px !important;
      top: 0px !important;
      left: -30px !important;
      border: #adb5bd solid 2px !important;
    }
  }
}

.outline {
  outline: none;
}
