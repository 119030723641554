@import "../../assets/theme/vars";

.box_iframe_video {
  iframe {
    width: 100%;
    min-height: 385px;
    border-radius: 0.5rem !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
  }
}
