@import '../../../assets/theme/vars';

.wrapper_galleries {
  .box_imgs_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .item_img_gallery {
      width: 12.5%;
      padding: 0.5rem;
      position: relative;
      &:hover .ico_trash{
        display: flex;
      }
      .ico_trash {
        display: none;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        background: #d80505f1;
        color: $white_text;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        font-size: 0.9rem;
        box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.25);
        &:hover {
          box-shadow: none;
          top: 0px;
          left: 0px;
        }
      }
    }
  }
}
