.oxxopay-section {
    text-align: center;
    background: #f0f0f0;
    width: 60%;
    margin: auto;
    border-radius: 15px;
    padding: 3% 10%;
    color: #5a595e;

    h1 {
        margin: 0;
        font-size: 30px;
        font-weight: bold;
    }

    p {
        font-size: 18px;
    }

    .oxxopay-p {
        margin: 0 0 5% 0;
    }

    img {
        width: 50%;
    }

    i {
        color: #4fb087;
        width: inherit;
        padding: 3%;
    }
}

@media (max-width:767px) {
    .oxxopay-section {
        width: 90%;
    }
}
