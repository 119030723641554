// FONTS SYSTEMS ---

@font-face {
  font-family: "is_light";
  src: url(../fonts/GothamNarrow-Light.eot);
  src: url(../fonts/GothamNarrow-Light.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-Light.ttf) format("truetype"), url(../fonts/GothamNarrow-Light.woff) format("woff"),
    url(../fonts/GothamNarrow-Light.svg) format("svg");
}
@font-face {
  font-family: "is_light_italic";
  src: url(../fonts/GothamNarrow-LightItalic.eot);
  src: url(../fonts/GothamNarrow-LightItalic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-LightItalic.ttf) format("truetype"),
    url(../fonts/GothamNarrow-LightItalic.woff) format("woff") url(../fonts/GothamNarrow-LightItalic.svg) format("svg");
}
@font-face {
  font-family: "is_book";
  src: url(../fonts/GothamNarrow-Book.eot);
  src: url(../fonts/GothamNarrow-Book.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-Book.ttf) format("truetype"), url(../fonts/GothamNarrow-Book.woff) format("woff"),
    url(../fonts/GothamNarrow-Book.svg) format("svg");
}
@font-face {
  font-family: "is_book_italic";
  src: url(../fonts/GothamNarrow-BookItalic.eot);
  src: url(../fonts/GothamNarrow-BookItalic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-BookItalic.ttf) format("truetype"),
    url(../fonts/GothamNarrow-BookItalic.woff) format("woff"), url(../fonts/GothamNarrow-BookItalic.svg) format("svg");
}
@font-face {
  font-family: "is_medium";
  src: url(../fonts/GothamNarrow-Medium.eot);
  src: url(../fonts/GothamNarrow-Medium.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-Medium.ttf) format("truetype"), url(../fonts/GothamNarrow-Medium.woff) format("woff"),
    url(../fonts/GothamNarrow-Medium.svg) format("svg");
}
@font-face {
  font-family: "is_medium_italic";
  src: url(../fonts/GothamNarrow-MediumItalic.eot);
  src: url(../fonts/GothamNarrow-MediumItalic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-MediumItalic.ttf) format("truetype"),
    url(../fonts/GothamNarrow-MediumItalic.woff) format("woff"),
    url(../fonts/GothamNarrow-MediumItalic.svg) format("svg");
}
@font-face {
  font-family: "is_bold";
  src: url(../fonts/GothamNarrow-Bold.eot);
  src: url(../fonts/GothamNarrow-Bold.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-Bold.ttf) format("truetype"), url(../fonts/GothamNarrow-Bold.woff) format("woff"),
    url(../fonts/GothamNarrow-Bold.svg) format("svg");
}
@font-face {
  font-family: "is_bold_italic";
  src: url(../fonts/GothamNarrow-BoldItalic.eot);
  src: url(../fonts/GothamNarrow-BoldItalic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-BoldItalic.ttf) format("truetype"),
    url(../fonts/GothamNarrow-BoldItalic.woff) format("woff"), url(../fonts/GothamNarrow-BoldItalic.svg) format("svg");
}
@font-face {
  font-family: "is_black";
  src: url(../fonts/GothamNarrow-Black.eot);
  src: url(../fonts/GothamNarrow-Black.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-Black.ttf) format("truetype"), url(../fonts/GothamNarrow-Black.woff) format("woff"),
    url(../fonts/GothamNarrow-Black.svg) format("svg");
}
@font-face {
  font-family: "is_black_italic";
  src: url(../fonts/GothamNarrow-BlackItalic.eot);
  src: url(../fonts/GothamNarrow-BlackItalic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/GothamNarrow-BlackItalic.ttf) format("truetype"),
    url(../fonts/GothamNarrow-BlackItalic.woff) format("woff"), url(../fonts/GothamNarrow-BlackItalic.svg) format("svg");
}

@mixin scrollbar_vertical {
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    border-radius: 20px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #8a8787;
  }
}

@mixin scrollbar_horizontal {
    /* height */
  &::-webkit-scrollbar {
    height: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dddddd;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }
}

@mixin bggrey {
  background: rgba(255, 255, 255, 0.99);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0.99)),
    color-stop(6%, rgba(242, 242, 242, 0.99)),
    color-stop(94%, rgba(242, 242, 242, 0.99)),
    color-stop(100%, rgba(255, 255, 255, 0.99))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
}

// COLOR SYSTEMS ---
$black: #303030;
$bg_grey: #f2f2f2;
$white_text: #fefefe;
$border_radius: 9px;
