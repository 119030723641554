@import "../../assets/theme/vars";

.wrapper_menu_aside {
  background: var(--primary_color);
  position: fixed;
  z-index: 99;
  top: 65px;
  left: 0;
  width: 215px;
  height: calc(100% - 65px);
  overflow: auto;
  color: $white_text;
  padding: 1.25rem 1rem .5rem;
  -webkit-transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
  }

  &.is-show {
    margin-left: 0;
  }
  &::after {
    content: "";
    -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 215px;
    height: 40%;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0)),
      color-stop(100%, rgba(0, 0, 0, 0.25))
    );
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
  }
  // .box_logo_company {
  //   text-align: center;
  //   padding: 1.25rem 0;
  //   margin-bottom: 2rem;
  // }
  .nav_menu_items {
    position: relative;
    z-index: 1;
    ul {
      padding-left: 0;
      list-style: none;
      hr {
        margin: 0.75rem 0;
        border-top: 2px solid rgba(255, 255, 255, 0.25);
      }
      .menu_item {
        margin-bottom: 0.3rem;
        width: 100%;
        font-family: "is_medium";
        padding: 0.6rem 0.75rem;
        border-radius: 7px;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        color: $white_text;
        cursor: pointer;
        &.active,
        &:hover {
          background: $white_text;
          color: $black;
          & .ico_imgs img {
            filter: invert(100%);
          }
        }
        .ico_imgs {
          width: 20px;
          min-width: 20px;
          height: 100%;
          margin-right: 0.65rem;
          img {
            width: 100%;
          }
        }
      }
      .ul_collapse {
        padding-left: 0.85rem;
        li {
          .submenu_item {
            padding: 0.5rem .5rem .5rem .75rem;
            display: block;
            margin-bottom: 0;
            color: $white_text;
            font-family: "is_book";
            border-radius: 7px;
            &:hover,
            &.active {
              background: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }
  .nav_legals{
    position: relative;
    z-index: 1;
    text-align: center;
    border-top: 2.5px solid rgba(255, 255, 255, 0.15);
    padding-top: .5rem;
    a {
      color: #ededed;
      cursor: pointer;
      font-size: .85rem;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
