@import "../../assets/theme/vars";

.wrapper_messages {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .template_messages {
    .box_group_messages {
      @include scrollbar_horizontal;
      overflow: auto;
      margin-bottom: 1rem;
      border-bottom: 2px solid #cccccc;
      .btn-group {
        min-width: 300px;
        margin-bottom: 0;
        .btns {
          min-width: 100px;
          border-radius: 7px 7px 0 0;
        }
      }
    }
    .content_messages {
      .box_filter_messg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.25rem;
        background: #ffffff;
        padding: 0.5rem;
        border-radius: 0.25rem;
      }
      .wrapper_table {
        .td_user {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .box_avatar_user {
            margin-right: 0.5rem;
            .avatar {
              width: 35px;
              min-width: 35px;
              height: 35px;
            }
          }
        }
        .tr_msg {
          background: #ffffff;
          &.is_read {
            background: transparent;
          }
        }
        .icon_tables {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.box_ordering {
  margin-right: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ttls_order {
    font-family: "is_medium";
    margin-right: 0.5rem;
  }
}

.wrapper_read_message {
  .content_info_msg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .box_avatar_user {
      margin-right: 0.5rem;
      .avatar {
        min-width: 60px;
        width: 60px;
        height: 60px;
      }
    }
    .box_info_msg {
      .text-muted {
        font-size: 0.9rem;
        color: #999999 !important;
        line-height: 20px;
      }
    }
  }
  .box_data_msg {
    font-size: 0.9rem !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .link_send {
      cursor: pointer;
      color: var(--primary_color);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .box_adjunts {
    margin-bottom: 1rem;
    .files {
      justify-content: flex-start;
    }
  }
}
