@import "../../assets/theme/vars";

.wrapper_audience {
  .content_ttls {
    background: #a6d7ff;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem;
    height: 170px;
    margin-bottom: 2rem !important;
    .box_img_surveys {
      text-align: center;
      min-width: 180px;
      img {
        width: 100%;
      }
    }
    .minw {
      max-width: 400px;
    }
  }
  .content_card_audience {
    padding: 1rem .25rem .25rem;
    .box_list_audience {
      // max-width: 750px;
      width: 100%;
      // margin: 0 auto;
      .item_audience {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.3rem;
        border-radius: 5px;
        margin: 0.15rem 0;
        font-size: 0.95rem;
        &:hover {
          background: $bg_grey;
        }
        .box_info_audience {
          width: 100%;
          padding-right: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .box_icon {
            min-width: 65px;
            font-size: 2.5rem;
            text-align: center;
          }
        }
        .box_btns{
          margin-top: .5rem;
        }
      }
    }
  }
}
