@import "../../../assets/theme/vars";

.wrapper_provider {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .box__providers {
    .box_logo_shop {
      img {
        height: 50px;
      }
    }
  }
  .info_media {
    margin-top: 1rem;
  }
}

.box_item_promo {
  .box_img_promo {
    padding: 0 0.5rem;
  }

  .box_info_promo {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0.45rem;
    padding: 0.75rem;
    position: relative;
    .name_promo {
      font-size: 1rem;
      min-height: 55px;
    }

    .btn_info_promo {
      position: absolute;
      top: 9px;
      right: 9px;
      color: #dcdcdc;
      cursor: pointer;
      font-size: 1rem;
      &:hover {
        color: var(--primary_color);
      }
    }

    .price-add {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .price {
        color: var(--primary_color);
      }

      .btn_add_promo {
        cursor: pointer;
        background: var(--primary_color);
        color: #ffffff;
        display: flex;
        height: 32px;
        width: 32px;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 0.95rem;
      }
    }
  }
}

.content_tooltip {
  display: none;
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  padding: 1rem 1.15rem 0.75rem 0.75rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  z-index: 9;
  text-align: justify;

  .btnclose_info {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1rem;
    color: var(--primary-color);
    cursor: pointer;
  }

  &.is-show {
    display: block;
  }
}

.wrapper_items_provider{
  margin-top: 4rem;
}