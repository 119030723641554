.wrapper_diary_admin {
    .box_data_calendar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item_data_calendar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 1rem;
            h6 {
                padding-left: 0.3rem !important;
            }
        }
    }
}
