@import "../../assets/theme/vars";

.wrapper_our_company {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .nav-tabs {
    border-bottom: 1px solid #666666;
    justify-content: space-around;
    .nav-item {
      margin: 0;
      width: 100%;
      .nav-link {
        min-width: auto !important;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        background: transparent;
        border: 0;
        &.active {
          font-family: "is_medium";
          color: #333333;
          border-bottom: 5px solid var(--primary_color);
        }
      }
    }
    .tab-content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .sec_list_post {
    background: #ededed;
    width: 100%;
    max-width: 290px;
    height: calc(100vh - 4rem);
    overflow: auto;
    @include scrollbar_vertical;
    .box_ttl_opt {
      position: relative;
      .btn_ellipsis {
        background: #dddddd !important;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
        border-radius: 50%;
        background: $bg_grey;
        cursor: pointer;
        color: #000000;
        position: absolute;
        top: 5px;
        right: 0;
      }
    }
    .divtop {
      padding: 0 1.25rem;
      padding-top: 2rem;
    }
    .box_ttls_sec {
      margin: 0 1.25rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 1.25rem 0.8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $white_text;
      &.article {
        background: #4282f5;
        .is_mes {
          text-transform: capitalize;
        }
      }
      &.gallery {
        background: #e76e0a;
        .ico_box_sec {
          color: #e76e0a;
        }
      }
      .ico_box_sec {
        background: $white_text;
        color: #4282f5;
        min-width: 50px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 0.5rem;
        text-transform: uppercase;
        font-family: "is_bold";
        font-size: 2rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
    .content_list_post {
      hr {
        margin: 0.5rem 0;
      }
      .item_list_post {
        background: $white_text !important;
        padding: 1.25rem 1rem;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #dedede;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        & > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &:hover {
          &.is_read {
            border-radius: 0.3rem 0.3rem 0 0 !important;
            color: $black;
            opacity: 1;
          }
        }
        &.is_read {
          background: transparent !important;
          color: #666666;
          box-shadow: none;
          border-radius: 0 !important;
          opacity: 0.9;
        }
        .box_avatar_user {
          min-width: 60px;
          .avatar {
            height: 50px;
            width: 50px;
          }
        }
        .date_post {
          font-size: 0.75rem;
          color: #999999;
        }
        .views_post {
          position: absolute;
          top: 0.35rem;
          right: 1rem;
          color: #999999;
          font-size: 0.7rem;
        }
      }
    }
  }

  .sec_post {
    width: 100%;
    height: calc(100vh - 4rem);
    position: relative;
    .back_ico {
      display: none;
      background: $white_text;
      padding: 0.25rem 0.35rem;
      border-radius: 5px;
      position: absolute;
      top: 10px;
      left: 25px;
      color: #666666;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    }
    .content_post {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content_info_post {
        padding: 2rem;
        width: 100%;
        overflow: auto;
        height: 100%;
        @include scrollbar_vertical;
        .info_post {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0.5rem 0 2rem;
          .box_avatar_user {
            min-width: 75px;
            .avatar {
              height: 65px;
              width: 65px;
            }
          }
        }
      }
      .sec_controls_post {
        width: 100%;
        height: auto;
        background: $white_text;
        box-shadow: 2px -2px 9px rgba(0, 0, 0, 0.1);
        .content_options_post {
          .box_files_post {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.75rem;
            border-bottom: 1px solid #d9d9d9;
            .likes {
              min-width: 50px;
              .like_heart {
                font-size: 1.2rem;
                margin-right: 0.25rem;
                color: #da2222;
              }
            }
          }
          .box_actions_post {
            font-size: 0.9rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.25rem 0.75rem;
            .box_tag_read {
              min-width: 110px;
              .tag_read {
                padding: 0.25rem 0.35rem;
                border-radius: 5px;
                background: #4282f5;
                color: #ffffff;
                cursor: pointer;
                &:hover {
                  background: #2f6fe6;
                }
              }
            }
            .box_btns_post {
              width: 100%;
              text-align: right;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .btn_action {
                padding: 0.25rem 0.35rem;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                margin-left: 0.5rem;
                &:hover {
                  background: $bg_grey;
                }
                & > i {
                  margin-right: 0.35rem;
                  font-size: 1.35rem;
                  &.far {
                    color: $black;
                  }
                  &.fas {
                    color: #da2222;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ReactGridGallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .ReactGridGallery_tile {
        width: calc(25% - 5px);
        margin: 5px !important;
        text-align: center;
        .ReactGridGallery_tile-viewport {
          height: 115px !important;
          img {
            height: 115px !important;
          }
        }
      }
    }
  }

  $optionDefaultColours: #ffc004, #c00000, #70ac47, #5d9cec, #70459f, #ff5735;

  .sec_aside_our_company {
    background: $white_text;
    border-left: 1px solid #dddddd;
    padding: 2rem 1.25rem;
    max-width: 250px;
    width: 100%;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .closex {
      display: none;
      font-size: 1.25rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .sec_more_post {
      .box_items_years {
        @include scrollbar_vertical;
        padding: 1rem 0;
        height: calc(100vh - 10em);
        overflow: auto;
      }
      .item_more_post {
        .box_collapse {
          .box_ul_month {
            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 0.5rem;
              cursor: pointer;
              color: #888888;
              @for $i from 1 through length($optionDefaultColours) {
                &:nth-child(#{$i}) {
                  --defaultBackground: #{nth($optionDefaultColours, $i)};
                }
                &:hover {
                  color: #222222;
                }
              }
              .dots {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                border-radius: 50%;
                font-family: "is_medium";
                color: $white_text;
                margin-right: 0.5rem;
                background: var(--optionBackground, var(--defaultBackground, #ed5565));
              }
              .name_mes {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
