.wrapper_block {
    position: relative;

    .modal-content {
        background: transparent !important;
        box-shadow: none;
        border: 0;
    }

    .box_content_block {
        display: flex;
        align-self: center;
        align-items: center;
        height: 100vh;
    }
}
.modal-open {
     overflow: auto !important; 
}